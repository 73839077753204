import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import plus from './plus.svg';
import minus from './minus.svg';
import visa from './visa.svg';
import mc from './mc.svg';
import desktopLogo from './desktop-logo.svg';
import styled from 'styled-components';
import { Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const apiUrl = process.env.REACT_APP_PUBLIC_URL || 'http://192.168.64.13:4444';
const useStyles = makeStyles({
  button: { 
    display: 'flex',
    textTransform: 'none',
    padding: '16px',
    borderRadius: '2px',
    boxShadow: 'none',
    minWidth: '280px',
    fontSize: '18px',
    fontWeight: 600,

    '&.MuiButton-contained': {
      background: '#338E42',
      color: '#fff',

      '&:hover, &.active': {
        background: '#86BB8E',
      },
    },

    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },

    '&.MuiButton-outlined': {
      border: '2px solid #338E42',
      color: '#338E42',
    },

    '&:hover, &.active': {
      boxShadow: 'none',
    },
  },
  textField: { 
    marginTop: '16px',
  },
});

toast.configure();

function App() {
  const [ticketTypes, setTicketTypes] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [purchaseInfo, setPurchaseInfo] = useState({});
  const [total, setTotal] = useState(0);
  const [state, setState] = useState('purchase');
  const [email, setEmail] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputEl = useRef(null);
  const recaptchaRef = useRef(null);
  const classes = useStyles();

  const addTicket = (ticket) => {
    const index = selectedTickets.findIndex((item) => item.code === ticket.code);

    index !== -1
      ? setSelectedTickets(selectedTickets.map((item) => item.code === ticket.code ? { ...item, quantity:  item.quantity + 1}  : item))
      : setSelectedTickets([...selectedTickets, { ...ticket, quantity: 1}]);
  }
  const removeTicket = (ticket) => {
    const index = selectedTickets.findIndex((item) => item.code === ticket.code);

    if (index === -1) return; 
    selectedTickets[index].quantity === 1
      ? setSelectedTickets([...selectedTickets.splice(0, index), ...selectedTickets.splice(index === 0 ? 1 : index )])
      : setSelectedTickets(selectedTickets.map((item) => item.code === ticket.code ? { ...item, quantity:  item.quantity - 1}  : item))
  }

  const togglePayWindow = async () => {
    const errorMessage = 'Произошла ошибка при формировании заказа, попробуйте ещё раз'; 

    try {
      setIsLoading(true);
      const response = await fetch(apiUrl + '/orders', {
        body: JSON.stringify({tickets: selectedTickets}),
        headers: {
          'Content-Type': 'application/json',
          'token': recaptchaValue,
        },
        method: 'PUT',
      });
      const data = await response.json();
      console.log(data);
      if (!data.error) {
        setPaymentInfo(data);
        await inputEl.current.togglePayWindow();
      } else {
        errorNotify(errorMessage);
      }
    } catch(e) {
      errorNotify(errorMessage);
      console.log(e);
    }
    setIsLoading(false);
  }

  const statusChangedHandler = async (status) => {
    const errorMessage = 'Произошла ошибка при формировании заказа, попробуйте ещё раз'; 

    if (status.detail === 'continue' || status.detail === 'close') {
      try {
        setIsLoading(true);
        const response = await fetch(apiUrl + `/orders/${paymentInfo.orderID}`, {
          body: JSON.stringify({tickets: selectedTickets, email}),
          headers: {
            'Content-Type': 'application/json',
            'token': recaptchaValue,
          },
          method: 'POST',
        });
        const data = await response.json();

        
        if (!data.error) {
          setPurchaseInfo(data);
          setSelectedTickets([]);
          setState('done');
        } else {
          errorNotify(errorMessage);
        }
      } catch(e) {
        errorNotify(errorMessage);
        console.log(e);
      }
      setIsLoading(false);
    }
  };

  const recaptchaOnChange = () => {
    setRecaptchaValue(recaptchaRef.current.getValue());
  }

  const warningNotify = (text) => {
    toast.warn(text);
  }

  const errorNotify = (text) => {
    toast.error(text, { autoClose: false });
  }

  useEffect(() => {
    setTotal(selectedTickets.reduce((acc, ticket) => { return acc + ticket.quantity * Number(ticket.price) }, 0));
  }, [selectedTickets]);

  useEffect(() => {
    const fetchData = async () => {
      const errorMessage = 'Произошла проблема с загрузкой данных, либо операционный день продажи билетов закрыт, попробуйте обновить страницу позже'; 

      try {
        const response = await fetch(apiUrl + '/get-ticket-types');
        const data = await response.json();
  
        if (!data.error) {
          setTicketTypes(data);
        } else {
          warningNotify(errorMessage);
        }
      } catch(e) {
        warningNotify(errorMessage);
        console.log(e);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const {current} = inputEl;

    current.addEventListener('statusChanged', statusChangedHandler);
    return () => {
      current.removeEventListener('statusChanged', statusChangedHandler);
    }
  });

  return (
    <Main>
      <div className="Wrapper">
        <HeaderWrapper>
          <Header>
            <Logo src={desktopLogo} />
            <nav>
              <a href="http://botsad.kz/ru/page/about">Об институте</a>
              <a href="http://botsad.kz/ru/page/service">Услуги</a>
              <a href="http://botsad.kz/ru/page/publichnaya_oferta">Публичная оферта</a>
              <a href="http://botsad.kz/ru/labs">Лаборатории</a>
              <a href="http://botsad.kz/ru/news">Новости</a>
              <a href="http://botsad.kz/ru/contacts">Контакты</a>
            </nav>
          </Header>
        </HeaderWrapper>
        {state === 'purchase' && (
          <>
            <H1>Купить билеты онлайн</H1>
            <Grid container>
              <Grid item md={7} xs={12}>
                <div>
                  {ticketTypes.map((type, id) => {
                    return (
                      <TicketCard key={id}>
                        {type.free && (
                          <TicketCardRow>
                            <TicketCardType>
                              <TicketCardName>{type.name}</TicketCardName>
                            </TicketCardType>
                            <TicketCardFreePrice>Бесплатно</TicketCardFreePrice>
                          </TicketCardRow>
                        )}
                        {!type.free && (
                          <>
                            <TicketCardRow>
                              <TicketCardType>
                                <TicketCardName>{type.name}</TicketCardName>
                                <TicketCardPriceMobile>{type.price}</TicketCardPriceMobile>
                                <TicketCardSubName>{type.subName}</TicketCardSubName>
                              </TicketCardType>
                              <TicketCardPrice>{type.price}</TicketCardPrice>
                              <Amount>
                                <AmountButton onClick={() => removeTicket(type)}><img alt="" src={minus} /></AmountButton>
                                <AmountValue>{selectedTickets.find((ticket) => ticket.code === type.code)?.quantity || 0}</AmountValue>
                                <AmountButton onClick={() => addTicket(type)}><img alt="" src={plus} /></AmountButton>
                              </Amount>
                            </TicketCardRow>
                            <TicketCardSubNameMobile>{type.subName}</TicketCardSubNameMobile>
                          </>
                        )}
                      </TicketCard>
                    )
                  })}
                </div>
              </Grid>
              <StyledRightGrid item md={5} xs={12}>
                Введите адрес эл.почты, на который мы можем отправить вам билет
                <TextField 
                  classes={{ root: classes.textField}} 
                  label="Эл.почта" 
                  variant="outlined" 
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <CardTypesBlock>
                  Оплатить можно картами: 
                  <CardTypes>
                    <img alt="" src={visa} />
                    <img alt="" src={mc} />
                  </CardTypes>
                </CardTypesBlock>
                <Total>
                  <li><span className="total-label">Количество билетов</span><span>{selectedTickets.reduce((acc, ticket)=> {return acc + ticket.quantity}, 0)}</span></li>
                  <li><span className="total-label">Итого</span><span>{`${total} тг`}</span></li>
                </Total>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  hl="ru"
                  sitekey="6LdlqN8ZAAAAAJU55c-MLr21ZVxAGJzUK6Qhe3qn"
                  onChange={recaptchaOnChange}
                />
                <StyledButton>
                  <Button classes={{ root: classes.button}} disabled={!selectedTickets.length || !email.length || !recaptchaValue || isLoading} onClick={togglePayWindow} variant="contained">{ `Оплатить ${total} тг`}</Button>
                </StyledButton>
              </StyledRightGrid>
            </Grid>
            <Grid container>
          <Grid item md={7} xs={12}>
            <h2>Детали по билетам</h2>
            <TicketDetails>
              <li>
                При покупке нескольких билетов в рамках  одного платежа, Клиент получает единый QR-код/штрих код по которому на территорию ГБС могут зайти ТОЛЬКО все участники билета ОДНОВРЕМЕННО. Так как после считывания QR-код/штрихкод он перестает действовать.
              </li>
              <li>
                Если Клиент хочет купить билеты разным людям на вход в разные дни, часы и т.д., ему необходимо приобретать билеты разными платежами, чтобы на каждый билет пришел отдельный QR-код/штрих код.
              </li>
              <li>
                Приобретенные билеты возврату и обмену не подлежат.
              </li>
              <li>
                Срок действия приобретённого билета – неограничен .    
              </li>
            </TicketDetails>
          </Grid>
        </Grid>
          </>
        )}
        {state === 'done' && (
          <DoneWrapper>
            <h1>Спасибо за покупку!</h1>
            <p className="discription">
              При входе в ГБС приложите QR-код к валидатору (распечатывать билет не обязательно)
            </p>
            <img src={purchaseInfo.qrCode} alt="QR code" width="250" height="250" />
            <p className="download-link"><a href={purchaseInfo.qrCode} download>Сохранить билет</a></p>
            <p>Мы также отправили QR-код на эл.почту <b>{email}</b></p>
            <ButtonsContainer>
              <Button classes={{ root: classes.button}} variant="outlined" href="http://botsad.kz/">На главную</Button>
              <Button classes={{ root: classes.button}} variant="contained" component="a" onClick={() => setState('purchase')}>Купить еще билет</Button>
            </ButtonsContainer>
          </DoneWrapper>
        )}
      </div>
      <frt-widget-ecom   
        ref={inputEl}
        merchant-id={paymentInfo.merchantID}
        currency="398"   
        amount={paymentInfo.amount}
        order-id={paymentInfo.paymentID}
        remote-id={paymentInfo.orderID} 
        session-id={paymentInfo.sessionID}
        json-class="TransactionSourcePurchase"
        button-text={`Оплатить ${total} тг`}
        button-style={JSON.stringify({display: 'none'})}
      >
      </frt-widget-ecom>
    </Main>
  );
}

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 55px auto 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  a:first-child {
    margin-right: 24px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    a:first-child {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
`;

const DoneWrapper = styled.div`
  padding: 2rem;
  text-align: center;

  h1 {
    margin: 0; 
    color: #338E42; 
    font-size: 24px;
  }

  img {
    display: block; 
    margin: 0 auto;
  }

  .discription {
    color: #35363D;
    font-weight: 600;
    margin: 24px 0;
  }

  .download-link {
    font-weight: bold;
    margin-top: 24px;

    a {
      color: #338E42 !important;
    }
  }
`;

const H1 = styled.h1`
  font-size: 46px;
  font-weight: 300;

  @media screen and (max-width: 600px) {
    font-size: 18px;
    font-weight: 500;
  }
`;

const Logo = styled.img`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  
  @media screen and (max-width: 1280px) {
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const HeaderWrapper = styled.header`
  height: 73px;
`;

const Header = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 73px;

  a {
    text-decoration: none;
    color: inherit;
    
    &:hover {
      color: #338E42;
    }

    &:not(:first-child) {
      padding-left: 20px;
    }
  }

  @media screen and (max-width: 1280px) {
    nav {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    position: absolute;
    width: 100%;
    left: 0;
    border-bottom: 1px solid #C3C6CD;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 25px;

  @media screen and (max-width: 600px) {
    padding: 0 16px;
  }
`;

const TicketCardName = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #338E42;
`;

const TicketCardSubName = styled.div`
  font-weight: 500;
  color: #909090;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const TicketCardSubNameMobile = styled(TicketCardSubName)`
  display: block;  
  padding-top: 8px;

  @media screen and (min-width: 600px) {
    display: none;
  }
`;

const TicketCardPrice = styled.div`
  font-size: 18px;
  font-weight: 500;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const TicketCardFreePrice = styled(TicketCardPrice)`
  flex-grow: 2 !important;

  @media screen and (max-width: 600px) {
    display: block;
    text-align: end;
  }
`;

const TicketCardPriceMobile = styled(TicketCardPrice)`
  display: block;

  @media screen and (min-width: 600px) {
    display: none;
  }
`;

const AmountButton = styled.span`
  cursor: pointer;

  &:first-child {
    margin-left: auto;
  }

  @media screen and (max-width: 600px) {
    img {
      height: 44px;
      width: 44px;
    }
  }
`;

const AmountValue = styled.div`
  width: 65px;
  font-size: 18px;
  font-weight: 500;
  align-self: center;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 24px;
  }
`;

const CardTypesBlock = styled.div`
  display: flex;
  margin: 32px 0;

  @media screen and (min-width: 960px) {
    margin: 24px 0;
  }
`;

const CardTypes = styled.div`
  margin-left: auto;

  img:first-child {
    padding-right: 16px;
  }
`;

const StyledRightGrid = styled(Grid)`
  margin-top: 24px !important;

  @media screen and (min-width: 960px) {
    padding-left: 130px;
    margin-top: 0 !important;
  }
`;

const TicketDetails = styled.ul`
  padding-inline-start: 20px;

  li:not(:first-child) {
    padding-top: 16px;
  }
`;

const Total = styled.ul`
  padding-inline-start: 0;
  color: #909090;
  font-weight: 500;

  li {
    display: flex;

    &:not(:first-child) {
      padding-top: 8px;
    }
  }

  .total-label { 
    flex-grow: 1;
  }
`;

const TicketCardRow = styled.div`
  display: flex;
  justify-content: space-between;

  > * {
    flex: 1 1 0;
  }
`;

const TicketCard = styled.div`
  padding: 26px 0;
  border-bottom: 1px solid #C3C6CD;

  @media screen and (min-width: 600px) {
    padding: 16px 0;
  }
`;

const TicketCardType = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;

  div:not(:first-child) {
    padding-top: 8px;
  }
`;

const Amount = styled.div`
  display: flex;
  flex-direction: row;
  height: 32px;

  @media screen and (max-width: 600px) {
    height: 44px;
  }  
`;

export default App;
